import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";

//components
import useSetPageData from "../../utils/useSetPageData";
import Hero from "../../components/panels/Hero";
import ComponentHeader from "../../components/panels/ComponentHeader";
// import LCATable from "../../components/in-pages/LCATable";
import SEO from "../../components/seo";

//styles
import s from "./careers.module.scss";

const CareersPage = ({ data, location }) => {
  const {
    SEO: seo,
    hero,
    dice_url,
    how_to_apply_description,
    benefits_description,
    benefits_cover_image,
    benefits_overview,
    training_description,
    training_list,
    college_description,
    college_cover_image,
    college_overview,
    lca_description,
    lca_notices,
    career_postings
  } = data.strapiPaCareers;

  useSetPageData(location);

  const image = data.allImageSharp.edges[0].node.fluid;

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | About`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].about_icon.childImageSharp.fixed}
      />

      <div
        style={{ marginBottom: "3rem" }}
        className={`container-fluid panel-padding`}
      >
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12 col-md-6`}>
              <div>
                <a href={dice_url} target="_blank" rel="noreferrer noopener">
                  <Img className={s.diceImage} fluid={image} />
                  <p className={s.diceText}>See all Dice job postings</p>
                </a>
              </div>
            </div>
            <div className={`col-sm-12 col-md-6`}>
              <ComponentHeader
                rootClassName={`bg-white ${s.headingTitle}`}
                textClassName={`text-primary`}
                text="HOW TO APPLY"
                // className={`${s.headingTitle}`}
              />
              <div className={`text-justify`}>
                <ReactMarkdown
                  source={how_to_apply_description}
                  escapeHtml={false}
                  renderers={{ link: LinkRenderer }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={`container-fluid panel-padding ${s.panelPadding}`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12 col-md-3`}>
              <ComponentHeader
                rootClassName={`bg-white ${s.headingTitle}`}
                textClassName={`text-primary`}
                text="BENEFITS"
                // className={`${s.headingTitle}`}
              />
              <p>
                <div className={`text-justify`}>{benefits_description}</div>
              </p>
            </div>
            <div className={`col-sm-12 col-md-9`}>
              <Img
                fluid={benefits_cover_image.childImageSharp.fluid}
                style={{ width: "100%" }}
              />

              <div className={`text-justify`}>
                <ReactMarkdown
                  source={benefits_overview}
                  escapeHtml={false}
                  renderers={{ link: LinkRenderer }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12 col-md-3`}>
              <ComponentHeader
                text="TRAINING"
                rootClassName={`bg-white ${s.headingTitle}`}
                textClassName={`text-primary`}
                // className={`${s.headingTitle}`}
              />

              <div className={`text-justify`}>{training_description}</div>
            </div>
            <div className={`col-sm-12 col-md-9`}>
              <div className={`row`}>
                {training_list.map((train, i) => (
                  <div className={`col-md-6 col-sm-12`} key={i}>
                    <Img
                      fixed={train.card_image.childImageSharp.fixed}
                      className={`${s.trainingImg}`}
                    />
                    <h3 className={`text-center`}>{train.heading}</h3>
                    <div className={`text-justify`}>{train.content}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12 col-md-3`}>
              <ComponentHeader
                text="COLLEGE RECRUITING"
                rootClassName={`bg-white ${s.headingTitle}`}
                textClassName={`text-primary`}
                // className={`${s.headingTitle}`}
              />
              <div className={`text-justify`}>
                <p>{college_description}</p>
              </div>
            </div>
            <div className={`col-sm-12 col-md-9`}>
              <Img
                fluid={college_cover_image.childImageSharp.fluid}
                style={{ width: "100%" }}
              />
              <p>{college_overview}</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <ComponentHeader
        text="U.S. Labor Condition Application (LCA)"
        rootClassName={`bg-white ${s.headingTitle}`}
        textClassName={`text-primary`}
        // className={`${s.headingTitle}`}
      />

      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12`}>
              <div className={`text-justify`}>
                <p
                  className={`overview-text`}
                  // className={layoutStyle.overviewText}
                >
                  {lca_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <table>
            <thead>
              <tr>
                <th>Posted Date</th>
                <th>Title</th>
                <th>Location</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {lca_notices.map((item, i) => {
                return (
                  <tr key={i}>
                    <td class={`${s.nonBreak}`}>{item.date}</td>
                    <td>{item.title}</td>
                    <td>{item.location}</td>
                    <td>
                      {item.notice_file && item.notice_file.publicURL && (
                        <a
                          // className={s.tableLink}
                          href={item.notice_file.publicURL}
                          download={item.notice_file.publicURL}
                        >
                          Download
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <LCATable LCAPostList={lca_notices} /> */}
        </div>
      </div>
      <hr />
      <ComponentHeader
        text="CURRENT CAREERS POSTINGS"
        rootClassName={`bg-white ${s.headingTitle}`}
        textClassName={`text-primary`}
        // className={`${s.headingTitle}`}
      />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          {career_postings[0].job_title.trim() !== "" ? (
            career_postings.map((career, i) => (
              <div className={`row`} key={i}>
                <div className={`col-sm-12`}>
                  <h4>{career.job_title}</h4>
                  <p className={s.dateandid}>
                    Job ID: {career.job_id} | Posted Date: {career.post_date}
                  </p>
                  <br />
                  <div className={`text-justify`}>
                    <ReactMarkdown
                      source={career.job_description}
                      escapeHtml={false}
                      renderers={{ link: LinkRenderer }}
                    />
                  </div>
                  <br />
                </div>
              </div>
            ))
          ) : (
            <div className={`text-center`}>
              Please check back later for job postings
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CareersPage;

export const pageQuery = graphql`
  {
    strapiPaCareers {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        about_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      dice_url
      how_to_apply_description
      benefits_description
      benefits_cover_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benefits_overview
      training_description
      training_list {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      college_description
      college_cover_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      college_overview
      lca_description
      lca_notices {
        id
        title
        location
        date
        notice_file {
          publicURL
        }
      }
      career_postings {
        id
        job_title
        job_id
        post_date
        job_description
      }
    }
    allImageSharp(
      filter: { original: { src: { regex: "/^.*dice-transparent.*$/" } } }
    ) {
      edges {
        node {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
